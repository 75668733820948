import React from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import portrait from '../../assets/images/portrait-orientation.png'

const BackgroundPortrait = ({color}) => {
    return (
        <DeviceOrientation>
            <Orientation orientation='landscape' alwaysRender={false}>
                <div 
                    style={{
                    backgroundColor: color.white,
                    width: '100vw',
                    height: '100vh',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 55,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}>
                    <img 
                        style={{
                        width: '80%',
                        height: '80%',
                        objectFit: 'contain'
                        }}
                        src={portrait} alt="portrait"/>
                </div>
        </Orientation>
      </DeviceOrientation>
    );
}

export default BackgroundPortrait;
