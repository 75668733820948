import axios from 'axios'
import Global from '../global.js';


//constantes

const dataProyecto = {
    list: null
}

//types
const GET_DATA_PROYECTO = 'GET_DATA_PROYECTO';
const GET_DATA_LIST_PROYECTOS = 'GET_DATA_LIST_PROYECTOS';

//reducer

export default function pageReducer(state = dataProyecto, action){
    switch (action.type) {
        case GET_DATA_PROYECTO:
            return {...state, [action.slug] : action.payload};
        case GET_DATA_LIST_PROYECTOS:
            return {...state, list: action.payload};
        default:
            return state
    }
}

//acciones
export const obtenerDataProyectoAccion = (slug) => async (dispatch, getState) => {
    // try {
    //     const res = await axios.get(`${Global.urlAdmin}project?slug=${slug}`)
    //     dispatch({
    //         type: GET_DATA_PROYECTO,
    //         payload: res.data[0],
    //         slug: slug
    //     })
    // } catch (error) {
    //     // console.log(error)
    // }
    try {
        axios.get(`${Global.urlAdmin}options/wl_work/${slug}`).then((res) => {
            dispatch({
                type: GET_DATA_PROYECTO,
                payload: res.data[0],
                slug: slug
            })
        }).catch(() => false);
    } catch (error) {
        // console.log(error)
    }
}

export const obtenerDataListProyectoAccion = (slug) => async (dispatch, getState) => {

    try {
        const res = await axios.get(`${Global.urlAdmin}project?per_page=100&page=1&orderby=menu_order&order=asc`);
        dispatch({
            type: GET_DATA_LIST_PROYECTOS,
            payload: res.data,
        })
    } catch (error) {
        // console.log(error)
    }
}