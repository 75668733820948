import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import pageReducer from './pageDucks';
import emailReducer from './emailDucks';
import projectReducer from './projectDucks';

const rootReducer = combineReducers({
    page: pageReducer,
    email: emailReducer,
    project: projectReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generarStore(){
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    return store
}