import React from 'react';
import './CtaContact.scss';

const CtaContact = ({data, Link}) => {
    return (
        <section className="contenedor-cta-contact">
            <div className="main-container">
                <h2>{data.title}</h2>
                <Link to="/contact" className="h3Base">
                    Contact us
                </Link>
            </div>
        </section>
    );
}

export default CtaContact;
