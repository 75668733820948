import React from 'react';
import './Error404.scss';
import C404 from '../../assets/images/404.png';
import BtnMainLink from '../../components/Buttons/BtnMainLink';

const Error404 = ({Link}) => {
    return (
        <section className="contenedor-404">
            <div className="main-container">
                <div className="contenido">
                    <img src={C404} alt="404"/>
                    <h2>Oh, no! This page does not exist.</h2>
                    <BtnMainLink Link={Link} to='/' text="Go to main page"/>
                </div>
            </div>
        </section>
    );
}

export default Error404;
