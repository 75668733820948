import React from 'react';
import './Background.scss';

const Background = () => {
    return (
        <div 
            id="contenedor-background"
            className={`contenedor-background`}
        >    
        </div>
    );
}

export default Background;
