import React, {useRef, useEffect} from 'react';
import './NavbarMobile.scss';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
// import { ReactComponent as LineaRoja } from '../../assets/images/linea_roja.svg';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataAccion} from '../../redux/pageDucks.js';
import {Markup} from 'interweave';

const NavbarMobile = ({useHistory, idPage, color, NavLink, animationBurgerMenuMobile, scrollEnable, scrollDisable, hideNav = false, opacity0Global}) => {
    const dispatch = useDispatch()
    const data = useSelector(store => store.page[idPage])
    const burgerMenu = useRef(null);
    const history = useHistory();

    const toogleMenu = () => {
        if(!animationBurgerMenuMobile.reversed()){
            scrollEnable();
        }else{
            scrollDisable();
        }
        animationBurgerMenuMobile.reversed(!animationBurgerMenuMobile.reversed());
    }
    const toogleMenuLogo = () => {
        if(!animationBurgerMenuMobile.reversed()){
            scrollEnable();
            animationBurgerMenuMobile.reversed(!animationBurgerMenuMobile.reversed());
        }
    }

    useEffect(() => {
        if(!data){
            dispatch(obtenerDataAccion(idPage));
        }
        // .to(burgerMenu.current, {
        //     duration: 0.2,
        //     backgroundColor: color.gray
        // })
        animationBurgerMenuMobile.to('#nav-mobile-top',{
            duration: 0.2,
            // backgroundColor: color.white
        }, '-=0.2').to(burgerMenu.current.children[0], {
            duration: 0.2,
            top: '50%',
            y: '-50%',
            rotation: 45
        }, '-=0.2').to(burgerMenu.current.children[1], {
            duration: 0.2,
            opacity: 0
        }, '-=0.2').to(burgerMenu.current.children[2], {
            duration: 0.2,
            top: '50%',
            y: '-50%',
            rotation: -45
        }, '-=0.2').to('#modal-menu', {
            duration: 0.4,
            y: 0,
        }, '-=0.4').reverse();
    }, [animationBurgerMenuMobile, color.salmon, color.gray, color.white, dispatch, idPage, data]);


    const beforeLink = (e) => {
        e.preventDefault();
        opacity0Global.restart();
        const url = e.currentTarget.getAttribute("href");
        setTimeout(() => {
            history.push(url);
        }, 200);
    }


    return (
        <div className="header-mobile">
            <div className="nav-mobile">
                <div id="nav-mobile-top" className="main-container contenedor-top">
                    <NavLink to="/" onClick={(e) => {toogleMenuLogo(); beforeLink(e);}} className={`contenedor-logo`}>
                        <Logo />
                        {/* <div className="contenedor-linea">
                            <LineaRoja />
                        </div> */}
                    </NavLink>
                    <div className="contenedor-right">
                        {data&&
                        <a href={data.acf.navbar.instagram_button.instagram_url} rel="noreferrer" target="_blank" className="contenedor-insta">
                            <span className="line"></span>
                            <span className="text-instagram-mobile">Insta</span>
                        </a>
                        }
                        <div className="burger-menu" ref={burgerMenu} onClick={() => {toogleMenu()}}>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
                {!hideNav&&
                    <div className="main-container contenedor-bottom">
                        {data&&
                            <Markup content={data.acf.navbar.right_corner_text} tagName="h3"/>
                        }
                        <div className="contenedor-titulo titulo-general">
                            <span>_A</span>
                            {/* <span>telier MA</span> */}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default NavbarMobile;
