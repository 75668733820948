import React, {useEffect} from 'react';
import './WorksMobile.scss';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataListProyectoAccion} from '../../redux/projectDucks';
// import {Markup} from 'interweave';

const WorksMobile = ({Link, setIsLoading, opacityGlobal, opacity0Global}) => {
    const dispatch = useDispatch()
    const data = useSelector(store => store.project.list)

    useEffect(() => {
        if(!data){
            dispatch(obtenerDataListProyectoAccion());
            window.scrollTo(0, 0);
        }
    }, [dispatch, data, setIsLoading]);

    if(data &&  opacityGlobal.paused()){
        opacityGlobal.restart();
    }

    return (
        <section className="contenedor-works-mobile">
            {data&&
                data.map((slide, index) => (
                    <Link key={index} to={`/works/${slide.slug}`} className="item">
                        <div className="contenido">
                            <h1>{slide.title.rendered}</h1>
                            <h3>{slide.acf.contents.subtitle}</h3>
                        </div>
                        <img 
                            src={slide.acf.preview_content.preview_image.sizes.medium} 
                            srcSet={`${slide.acf.preview_content.preview_image.sizes.medium} 226w, ${slide.acf.preview_content.preview_image.sizes.large} 674w`} 
                            sizes="(max-width: 600px) 226px"
                            alt={(slide.acf.preview_content.preview_image.description)?slide.acf.preview_content.preview_image.description:slide.acf.preview_content.preview_image.title}
                        />
                    </Link>
                ))
            }
        </section>
    );
}

export default WorksMobile;
