import React, {useEffect} from 'react';
import './ModalMenu.scss';
import { ReactComponent as Asterisco } from '../../assets/images/asterisco.svg';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataAccion} from '../../redux/pageDucks.js';

const ModalMenu = ({idPage, telFix, NavLink, animationBurgerMenuMobile, scrollEnable, useHistory, opacity0Global}) => {
    const dispatch = useDispatch()
    const data = useSelector(store => store.page[idPage]);
    const history = useHistory();
    useEffect(() => {
        if(!data){
            dispatch(obtenerDataAccion(idPage));
        }
    }, [dispatch, idPage, data]);

    const toogleMenu = () => {
        if(!animationBurgerMenuMobile.reversed()){
            scrollEnable();
            animationBurgerMenuMobile.reversed(!animationBurgerMenuMobile.reversed());
        }
    }

    const beforeLink = (e) => {
        e.preventDefault();
        opacity0Global.restart();
        const url = e.currentTarget.getAttribute("href");
        setTimeout(() => {
            history.push(url);
        }, 200);
    }

    return (
        <div id="modal-menu" className="modal-menu">
            {data&&
            <div className="main-container">
                <div className="contenedor-menu-mobile">
                    <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(); beforeLink(e);}} to="/" className="item h3Base"><div><Asterisco /></div>STUDIO</NavLink>
                    <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(); beforeLink(e);}} to="/works" className="item h3Base"><div><Asterisco /></div>WORK</NavLink>
                    <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(); beforeLink(e);}} to="/journal" className="item h3Base"><div><Asterisco /></div>JOURNAL</NavLink>
                    <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(); beforeLink(e);}} to="/contact" className="item h3Base"><div><Asterisco /></div>SAY HELLO!</NavLink>
                </div>
                <div className="contenedor-datos">
                    <a href={`tel:${telFix(data.acf.navbar.phone)}`} className="contenedor-dato">
                        <span className="p">C:</span><p>{data.acf.navbar.phone}</p>
                    </a>
                    <a href={`mailto:${telFix(data.acf.navbar.email)}`} className="contenedor-dato">
                        <span className="p">M:</span><p>{data.acf.navbar.email}</p>
                    </a>
                </div>
            </div>
            }
        </div>
    );
}

export default ModalMenu;
