import Global from '../global.js';
import axios from 'axios'

//const
const dataEmailRespose = {
    state: false,
    status: ''
}

//types
const SEND_EMAIL = 'SEND_EMAIL'
const SET_COMPLETE = 'SET_COMPLETE'

//reducer

export default function emailReducer(state = dataEmailRespose, action){
    switch (action.type) {
        case SEND_EMAIL:
            return {...state, status: (action.payload === 'success')?'complete':'error', state: true}
        case SET_COMPLETE:
            return {...state, state: false}
        default:
            return state
    }
}

//acciones
export const enviarEmailAccion = (data) => async (dispatch, getState) => {
    axios.post(`${Global.urlEmail}/php/mail.php`, data).then((res) => {
        dispatch({
            type: SEND_EMAIL,
            payload: res.data.code
        })
    }).catch(() => {
        dispatch({
            type: SEND_EMAIL,
            payload: 'error'
        })
    });
}

export const completeAccion = (data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_COMPLETE,
        })
    } catch (error) {
        // console.log(error)
    }
}