import React, { useState, useEffect, useRef} from 'react';
import BtnMain from '../../components/Buttons/BtnMain';
import './Contact.scss';
import {useDispatch, useSelector} from 'react-redux';
import {enviarEmailAccion} from '../../redux/emailDucks';
import {obtenerDataAccion} from '../../redux/pageDucks.js';
import {completeAccion} from '../../redux/emailDucks';
import { useForm } from "react-hook-form";
import ESuccess  from '../../assets/images/emoticon-success.svg'; 
import EError from '../../assets/images/emoticon-error.svg'; 
import EWarning from '../../assets/images/emoticon-warning.svg'; 
import CaritaSonriente from '../../assets/images/carita-sonriente.svg'; 
import CaritaGiñando from '../../assets/images/carita-giñando.svg'; 
import CaritaAlegre from '../../assets/images/carita-alegre.svg'; 
import {Markup} from 'interweave';
import gsap from 'gsap';

const Contact = ({idPage, isTabletOrMobile, telFix, url, opacityGlobal, opacity0Global}) => {
    const dispatch = useDispatch();
    const data = useSelector(store => store.page[idPage]);
    const dataEmail = useSelector(store => store.email);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [statusText, setStatusText] = useState('');
    const [sendingFlag, setSendingFlag] = useState(false);
    const [envioCorreo, setEnvioCorreo] = useState(false);
    const carita1 = useRef(null);
    const carita2 = useRef(null);
    const carita3 = useRef(null);
    const box = useRef(null);


    useEffect(() => {
        if(!data){
            dispatch(obtenerDataAccion(idPage));
        }
        const animationCaritas1 = gsap.timeline({repeat: -1, yoyo: true, delay: 1});
        const animationCaritas2 = gsap.timeline({repeat: -1, yoyo: true, delay: 1});
        const animationCaritas3 = gsap.timeline({repeat: -1, yoyo: true, delay: 1});
        // var minX = 0;
        // var minY = 0;
        // var size = carita1.current.offsetWidth;
        // var maxX = box.current.offsetWidth  - size;
        // var maxY = box.current.offsetHeight - size;
        animationCaritas1.to(carita1.current,{
            duration: gsap.utils.random(3, 6),
            x: `${gsap.utils.random(["-=", "+="])}${gsap.utils.random(70, 140)}px`,
            rotation: `${gsap.utils.random(["-=", "+="])}${gsap.utils.random(0, 360)}`
        });
        animationCaritas2.to(carita2.current,{
            duration: gsap.utils.random(3, 6),
            x: `${gsap.utils.random(["-=", "+="])}${gsap.utils.random(-70, 70)}px`,
            y: `+=${gsap.utils.random(0, 70)}px`,
            rotation: `${gsap.utils.random(["-=", "+="])}${gsap.utils.random(90, 180)}`
        });
        animationCaritas3.to(carita3.current,{
            duration: gsap.utils.random(3, 6),
            x: `${gsap.utils.random(["-=", "+="])}${gsap.utils.random(-70, 70)}px`,
            y: `+=${gsap.utils.random(0, 70)}px`,
            rotation: `${gsap.utils.random(["-=", "+="])}${gsap.utils.random(90, 180)}`
        });
        window.scrollTo(0, 0);
    }, [dispatch, idPage, data, carita1, carita2, carita3]);
    
    if(data &&  opacityGlobal.paused()){
        opacityGlobal.restart();
    }

    const onSubmit = (dataFormulario) => {
        const dataForm = new FormData();
        dataForm.append('correosDestino', JSON.stringify(data.acf.contact_information.contact_form.destination_emails));
        dataForm.append('url', url);
        Object.keys(dataFormulario).map((value, index) => (
            dataForm.append(value.toString(), dataFormulario[value].toString())
        ));
        dispatch(enviarEmailAccion(dataForm))
        setEnvioCorreo(true);
        setStatusText('Sending...');
        setSendingFlag(true);
    }

    if(dataEmail.state && envioCorreo){
        setEnvioCorreo(false);
        setSendingFlag(false);
        if(dataEmail.status === 'error'){
            setStatusText('The message couldn’t be sent due to an error. Please try later.');
            setTimeout(() => {
                dispatch(completeAccion());
            }, 10);
        }else{
            setStatusText('The message has been sent successfully.');
            setTimeout(() => {
                dispatch(completeAccion());
            }, 10);
        }
        setTimeout(() => {
            setStatusText('');
        }, 3000);
    }

    return (
        <section className="contenedor-contact">
            <div ref={box} className="box">
                <div ref={carita1} className="carita sonriente">
                    <img src={CaritaSonriente} alt="carita-sonriente"/>
                </div>
                <div ref={carita2} className="carita giñando">
                    <img src={CaritaGiñando} alt="carita-giñando"/>
                </div>
                <div ref={carita3} className="carita alegre">
                    <img src={CaritaAlegre} alt="carita-alegre"/>
                </div>
            </div>
            {data&&
            <div className="main-container">
                <div className="contenedor-left">
                    {!isTabletOrMobile&&
                        <p>
                            <Markup content={data.acf.contact_information.address} tagName="span"/>
                            <a href={`tel:${telFix(data.acf.contact_information.phone)}`}>{data.acf.contact_information.phone}</a>
                        </p>
                    }                   
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input text-input">
                            <input 
                                placeholder="Name"
                                {...register("name", {
                                    required: 'Name is required' 
                                })}
                            ></input>
                        </div>
                        <div className="input text-input">
                            <input 
                                placeholder="E-mail"
                                {...register("email", {
                                    required: 'E-mail is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "The email address isn’t valid."
                                    }
                                })}
                            ></input>
                        </div>
                        <div className="input text-input">
                            <input 
                                placeholder="Subject"
                                {...register("subject", {
                                    required: 'Subjet is required',
                                })}
                            ></input>
                        </div>
                        <div className="input text-input">
                            <textarea 
                                placeholder="Message"
                                {...register("message", {
                                    required: 'Message is required',
                                })}
                            >
                            </textarea>
                        </div>
                        <BtnMain text="submit" disable={sendingFlag}/>
                    </form>
                    <div className="contenedor-mensajes-padre">
                        <div className="contenedor-img">
                                {errors.name?
                                    <img src={EWarning} alt="emoticon-warning"/>
                                :errors.message?
                                    <img src={EWarning} alt="emoticon-warning"/>
                                :errors.subject?
                                    <img src={EWarning} alt="emoticon-warning"/>
                                :errors.email?
                                    <img src={EWarning} alt="emoticon-warning"/>
                                :sendingFlag?
                                    <div className="sending">

                                    </div>
                                :dataEmail.status === 'error'?
                                    <img src={EError} alt="emoticon-error"/>
                                :statusText?
                                    <img src={ESuccess} alt="emoticon-success"/>
                                :
                                    ''
                                }
                        </div>
                        <div className="contenedor-mensaje">
                            <div className="text-form-status">
                                {errors.name?
                                    errors.name.message
                                :errors.message?
                                    errors.message.message
                                :errors.subject?
                                    errors.subject.message
                                :errors.email?
                                    errors.email.message
                                :   statusText
                                }
                            </div>
                        </div>
                    </div>
                    {isTabletOrMobile&&
                        <>
                            <p>
                                <Markup content={data.acf.contact_information.address} tagName="span"/>
                            </p>
                            <a href={`tel:${telFix(data.acf.contact_information.phone)}`}>{data.acf.contact_information.phone}</a>
                            <Markup content={data.acf.contact.text} tagName="p"/>
                        </>
                    }  
                </div>
                <div className="contenedor-right">
                    <h1>{data.acf.contact.title}</h1>
                    <Markup content={data.acf.contact.subtitle} tagName="h2"/>
                    {!isTabletOrMobile&&
                        <Markup content={data.acf.contact.text} tagName="p"/>
                    }
                </div>
            </div>
            }
        </section>
    );
}

export default Contact;
