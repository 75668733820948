import React from 'react';
import './BtnMain.scss';

const BtnMainLink = ({Link, to, text, disable}) => {
    return (
        <Link to={to} className={`btn-main text-input ${disable?'disable':''}`}>
            <span>{text}</span>
        </Link>
    );
}

export default BtnMainLink;
