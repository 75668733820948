import React from 'react';
import './BtnMain.scss';

const BtnMain = ({text, disable}) => {
    return (
        <button className={`btn-main text-input ${disable?'disable':''}`}>
            <span>{text}</span>
        </button>
    );
}

export default BtnMain;
