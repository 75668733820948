import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './normalize.scss';
import './index.scss';
import {Provider} from 'react-redux';
import generateStore from './redux/store';
const store = generateStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
