import React, {useEffect} from 'react';
import StudioIntroduction from '../../components/StudioIntroduction/StudioIntroduction';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataAccion} from '../../redux/pageDucks.js';
import Team from '../../components/Team/Team';
import CtaContact from '../../components/CtaContact/CtaContact';

const Studio = ({paddingLeft, idPage, Link, videoIsLoadOneTime, setVideoIsLoadOneTime, isTabletOrMobile, timeVideo, setTimeVideo, opacityGlobal, opacity0Global}) => {
    const dispatch = useDispatch()
    const data = useSelector(store => store.page[idPage])
    useEffect(() => {
        if(!data){
            // setIsLoading(true)
            dispatch(obtenerDataAccion(idPage));
        }
        window.scrollTo(0, 0);
    }, [dispatch, idPage, data]);
    return (
        <>
            {data !== null&&<>
                <StudioIntroduction paddingLeft={paddingLeft} data={data.acf.introduction}/>
                <Team opacityGlobal={opacityGlobal} isTabletOrMobile={isTabletOrMobile} timeVideo={timeVideo} setTimeVideo={setTimeVideo} videoIsLoadOneTime={videoIsLoadOneTime} setVideoIsLoadOneTime={setVideoIsLoadOneTime} data={data.acf.team} dataImages={data.acf.parallax_images}/>
                <CtaContact data={data.acf.cta_contact} Link={Link}/>
            </>}
        </>
    );
}

export default Studio;
