import React, {useState, useEffect} from 'react';
import Global from './global.js';
import { useMediaQuery } from 'react-responsive';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  // Redirect,
  useParams,
  Redirect,
  useHistory
} from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Menu from './components/Menu/Menu';
import gsap from 'gsap';
import Background from './components/Background/Background.jsx';
// import Home from './pages/Home/Home.jsx';
import NavbarMobile  from './components/NavbarMobile/NavbarMobile';
import ModalMenu from './components/ModalMenu/ModalMenu';
import Journal from './pages/Journal/Journal';
import Studio from './pages/Studio/Studio';
import Contact from './pages/Contact/Contact';
import Works from './pages/Works/Works';
import Loading from './components/Loading/Loading';
import Work from './pages/Work/Work.jsx';
import WorksMobile from './pages/Works/WorksMobile.jsx';
import Error404 from './pages/Error404/Error404.jsx';
import BackgroundPortrait from './components/backgroundPortrait/BackgroundPortrait.jsx';

function App() {
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [paddingRight, setPaddingRight] = useState(0);
  const [opacityGlobal] = useState(gsap.timeline({paused: true}));
  const [opacity0Global] = useState(gsap.timeline({paused: true}));
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const [animationBurgerMenu] = useState(gsap.timeline());
  const [animationBurgerMenuMobile] = useState(gsap.timeline());
  const [isLoading, setIsLoading] = useState(false);
  const [videoIsLoadOneTime, setVideoIsLoadOneTime] = useState(false);
  const [timeVideo, setTimeVideo] = useState(0);
  // Calcula el tamaño de pantalla mobile
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  let vhResize = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vhResize', `${vhResize}px`);
  let vhResizeFull = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vhResizeFull', `${vhResize}px`);
  document.documentElement.style.setProperty('--paddingLeft', `${paddingLeft}px`);
  document.documentElement.style.setProperty('--paddingRight', `${paddingRight}px`);


  const targetElement = document.querySelector('html');
  let scrollPosition = 0;

  const scrollDisable = () => {
    scrollPosition = window.pageYOffset;
    targetElement.style.overflowY = 'hidden';
    targetElement.style.width = '100%';
  }
  const scrollEnable = () => {
    try {
      targetElement.style.overflowY = 'unset';
      targetElement.style.width = 'auto';
      window.scrollTo(0, scrollPosition);
    } catch (error) {
      
    }

  }
  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        vhResize = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vhResize', `${vhResize}px`);
        vhResizeFull = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vhResizeFull', `${vhResizeFull}px`);
      }, 300);
    });
    window.addEventListener("resize", () => {
      setTimeout(() => {
        vhResizeFull = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vhResizeFull', `${vhResizeFull}px`);
      }, 300);
    });
    opacityGlobal.to('#root', {
      duration: 0.8,
      delay: 0.2,
      opacity: 1
    });
    opacity0Global.to('#root', {
      duration: 0.001,
      opacity: 0,
      onComplete: () => {
        opacityGlobal.paused(true);
      }
    });
  }, [vhResize, opacityGlobal, opacity0Global]);

  return (
    // <DeviceOrientation lockOrientation={'portrait'}>
    //   <Orientation orientation='portrait' alwaysRender={false}>
      <Router>
        <Loading isLoading={isLoading}/>
        <Background/>
        <Switch>
          <Route exact path="/works/:slug">
            {isTabletOrMobile?<>
              <BackgroundPortrait color={Global.color}/>
              <ModalMenu opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} telFix={Global.telFix} idPage={Global.pages.contact} NavLink={NavLink} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
              <NavbarMobile opacity0Global={opacity0Global} useHistory={useHistory} hideNav="true" scrollEnable={scrollEnable} scrollDisable={scrollDisable} idPage={Global.pages.contact} NavLink={NavLink} color={Global.color} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
            </>:<>
              <Navbar interna={true} useHistory={useHistory} opacity0Global={opacity0Global} hideNav="true" idPage={Global.pages.contact} NavLink={NavLink} telFix={Global.telFix} animationBurgerMenu={animationBurgerMenu}/>
              <Menu opacity0Global={opacity0Global} useHistory={useHistory} hideNav="true" color={Global.color} left={true} animationBurgerMenu={animationBurgerMenu} NavLink={NavLink} isTabletOrMobile={isTabletOrMobile}/>
            </>}
            <Work opacityGlobal={opacityGlobal} opacity0Global={opacity0Global} useParams={useParams} setIsLoading={setIsLoading} idPage={Global.pages.work} Link={Link} NavLink={NavLink} isTabletOrMobile={isTabletOrMobile}/>
          </Route>
          <Route exact path="/works">
            {isTabletOrMobile?<>
              <BackgroundPortrait color={Global.color}/>
              <ModalMenu opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} telFix={Global.telFix} idPage={Global.pages.contact} NavLink={NavLink} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
              <NavbarMobile opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} idPage={Global.pages.contact} NavLink={NavLink} color={Global.color} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
            </>:<>
              <Navbar setPaddingLeft={setPaddingLeft} setPaddingRight={setPaddingRight} useHistory={useHistory} opacity0Global={opacity0Global} idPage={Global.pages.contact} NavLink={NavLink} telFix={Global.telFix} animationBurgerMenu={animationBurgerMenu}/>
              <Menu opacity0Global={opacity0Global} useHistory={useHistory} color={Global.color} left={false} animationBurgerMenu={animationBurgerMenu} NavLink={NavLink}/>
            </>}
            {!isTabletOrMobile?<>
              <Works opacityGlobal={opacityGlobal} opacity0Global={opacity0Global} setIsLoading={setIsLoading} idPage={Global.pages.work} Link={Link}/>
              </>:
              <WorksMobile opacityGlobal={opacityGlobal} opacity0Global={opacity0Global} setIsLoading={setIsLoading} idPage={Global.pages.work} Link={Link}/>
            }
          </Route>
          <Route exact path="/contact">
            {isTabletOrMobile?<>
              <BackgroundPortrait color={Global.color}/>
              <ModalMenu opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} telFix={Global.telFix} idPage={Global.pages.contact} NavLink={NavLink} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
              <NavbarMobile opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} idPage={Global.pages.contact} NavLink={NavLink} color={Global.color} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
            </>:<>
              <Navbar setPaddingLeft={setPaddingLeft} setPaddingRight={setPaddingRight} useHistory={useHistory} opacity0Global={opacity0Global} idPage={Global.pages.contact} NavLink={NavLink} telFix={Global.telFix} animationBurgerMenu={animationBurgerMenu}/>
              <Menu opacity0Global={opacity0Global} useHistory={useHistory} color={Global.color} left={false} animationBurgerMenu={animationBurgerMenu} NavLink={NavLink}/>
            </>}
            <Contact opacityGlobal={opacityGlobal} opacity0Global={opacity0Global} setIsLoading={setIsLoading} telFix={Global.telFix} idPage={Global.pages.contact} isTabletOrMobile={isTabletOrMobile} url={Global.urlEmail}/>
          </Route>
          <Route exact path="/journal">
            {isTabletOrMobile?<>
              <BackgroundPortrait color={Global.color}/>
              <ModalMenu opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} telFix={Global.telFix} idPage={Global.pages.contact} NavLink={NavLink} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
              <NavbarMobile opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} idPage={Global.pages.contact} NavLink={NavLink} color={Global.color} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
            </>:<>
              <Navbar setPaddingLeft={setPaddingLeft} setPaddingRight={setPaddingRight} useHistory={useHistory} opacity0Global={opacity0Global} idPage={Global.pages.contact} NavLink={NavLink} telFix={Global.telFix} animationBurgerMenu={animationBurgerMenu}/>
              <Menu opacity0Global={opacity0Global} useHistory={useHistory} color={Global.color} left={true} animationBurgerMenu={animationBurgerMenu} NavLink={NavLink}/>
            </>}
            <Journal opacityGlobal={opacityGlobal} opacity0Global={opacity0Global} setIsLoading={setIsLoading} idPage={Global.pages.journal}/>
          </Route>
          <Route exact path="/404">
            <Error404 Link={Link}/>
          </Route>
          <Route exact path="/">
            {isTabletOrMobile?<>
              <BackgroundPortrait color={Global.color}/>
              <ModalMenu opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} telFix={Global.telFix} idPage={Global.pages.contact} NavLink={NavLink} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
              <NavbarMobile opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} idPage={Global.pages.contact} NavLink={NavLink} color={Global.color} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
            </>:<>
              <Navbar paddingRight={paddingRight} setPaddingRight={setPaddingRight} paddingLeft={paddingLeft} setPaddingLeft={setPaddingLeft} useHistory={useHistory} opacity0Global={opacity0Global} idPage={Global.pages.contact} NavLink={NavLink} telFix={Global.telFix} animationBurgerMenu={animationBurgerMenu}/>
              <Menu opacity0Global={opacity0Global} useHistory={useHistory} color={Global.color} left={false} animationBurgerMenu={animationBurgerMenu} NavLink={NavLink}/>
            </>}
            <Studio paddingRight={paddingRight} paddingLeft={paddingLeft} opacityGlobal={opacityGlobal} opacity0Global={opacity0Global} isTabletOrMobile={isTabletOrMobile} timeVideo={timeVideo} setTimeVideo={setTimeVideo} videoIsLoadOneTime={videoIsLoadOneTime} setVideoIsLoadOneTime={setVideoIsLoadOneTime} setIsLoading={setIsLoading} idPage={Global.pages.studio} Link={Link}/>  
          </Route>
          {/* <Route exact path="/"> */}
            {/* {isTabletOrMobile?<>
              <ModalMenu opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} telFix={Global.telFix} idPage={Global.pages.contact} NavLink={NavLink} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
              <NavbarMobile opacity0Global={opacity0Global} useHistory={useHistory} scrollEnable={scrollEnable} scrollDisable={scrollDisable} idPage={Global.pages.contact} NavLink={NavLink} color={Global.color} animationBurgerMenuMobile={animationBurgerMenuMobile}/>
            </>:<>
              <Navbar useHistory={useHistory} idPage={Global.pages.contact} NavLink={NavLink} telFix={Global.telFix} animationBurgerMenu={animationBurgerMenu}/>
              <Menu opacity0Global={opacity0Global} useHistory={useHistory} color={Global.color} left={false} animationBurgerMenu={animationBurgerMenu} NavLink={NavLink}/>
            </>}
            <Home Link={Link} isTabletOrMobile={isTabletOrMobile} videoIsLoadOneTime={videoIsLoadOneTime} setVideoIsLoadOneTime={setVideoIsLoadOneTime} setIsLoading={setIsLoading} idPage={Global.pages.home}/> */}
          {/* </Route> */}
          {/* <Redirect from="/" to="/studio"/> */}
          <Redirect from="*" to="/404"/>
        </Switch>
      </Router>
    //   </Orientation>
    // </DeviceOrientation>
  );
}

export default App;
