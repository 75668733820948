import React, {useEffect, useRef, useState} from 'react';
import './Works.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataListProyectoAccion} from '../../redux/projectDucks';
import {Markup} from 'interweave';

const Works = ({Link, setIsLoading, opacityGlobal, opacity0Global}) => {
    const dispatch = useDispatch()
    const data = useSelector(store => store.project.list)
    const [swiper, setSwiper] = useState(null);
    const sliderMenu = useRef(null);
    const slideTo = (e) => {
        swiper.slideTo(parseInt(e.currentTarget.getAttribute('data-num')))
        for (let i = 0; i < sliderMenu.current.children.length; i++) {
            sliderMenu.current.children[i].classList.remove('isActive');
        }
        e.currentTarget.classList.add('isActive');
    }
    useEffect(() => {
        if(!data){
            // setIsLoading(true)
            dispatch(obtenerDataListProyectoAccion());
        }
        window.scrollTo(0, 0);
    }, [dispatch, data, setIsLoading]);

    if(data){
        // setIsLoading(false);
    }

    if(data &&  opacityGlobal.paused()){
        opacityGlobal.restart();
    }

    return (
        <section className="contenedor-works">
            <div ref={sliderMenu} className="contenedor-left">
                {data&&
                    data.map((project, index) => (
                        <Link to={`/works/${project.slug}`} onMouseEnter={(e) => {slideTo(e)}} data-num={index} key={index} className={`item text-proyects ${(index === 0)?'isActive':''}`}>
                            <Markup key={index} content={project.acf.preview_content.menu_text} allowList={["strong"]}/>
                        </Link>
                    ))
                }
            </div>
            <div className="contenedor-right">
                <Swiper
                    onSwiper={setSwiper}
                    slidesPerView={1}
                    direction='vertical'
                    allowTouchMove={false}
                > 
                {data&&
                    data.map((project, index) => (
                        <SwiperSlide key={index}>
                            <Link to={`/works/${project.slug}`}>
                                <div className="contenido">
                                    <h1>{project.title.rendered}</h1>
                                    <h3>{project.acf.contents.subtitle}</h3>
                                </div>
                                <div className="item-image">
                                    <img src={project.acf.preview_content.preview_image.url} alt="prev-img"/>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))
                }
                </Swiper>
            </div>
        </section>
    );
}

export default Works;
