import React, {useEffect, useState} from 'react';
import './Work.scss';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataProyectoAccion} from '../../redux/projectDucks';
import {obtenerDataListProyectoAccion} from '../../redux/projectDucks';
import {Markup} from 'interweave';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import SwiperCore, { Mousewheel  } from 'swiper/core';
SwiperCore.use([Mousewheel]);


const Work = ({Link, NavLink, useParams, isTabletOrMobile, opacityGlobal, opacity0Global}) => {
    const {slug} = useParams();
    const dispatch = useDispatch();
    const data = useSelector(store => store.project[slug]);
    const dataListProjects = useSelector(store => store.project.list);
    const [dataMobile, setDataMobile] = useState([]);
    const [isEnter, setIsEnter] = useState(false);

    useEffect(() => {
        if(!data){
            dispatch(obtenerDataProyectoAccion(slug));
        }
        if(!dataListProjects){
            dispatch(obtenerDataListProyectoAccion());
        }
        window.scrollTo(0, 0);
    }, [dispatch, slug, data, dataListProjects]);

    if(data &&  opacityGlobal.paused()){
        opacityGlobal.restart();
    }

    if(data && !isEnter){
        let newImages = [];
        data.images.forEach(imagesGroup => {
            switch (imagesGroup.type) {
                case "1":
                    if(imagesGroup.image !== false)
                        newImages.push(imagesGroup.image);
                    break;
                case "2":
                    if(imagesGroup.image !== false)
                        newImages.push(imagesGroup.image);
                    if(imagesGroup.image !== false)
                        newImages.push(imagesGroup["image-2"]);
                    break;
                default:
                    break;
            }
            // Object.keys(imagesGroup).map((entri) => {
            //     console.log(imagesGroup)
            //     if(entri !== 'type' && imagesGroup[entri] !== false){
            //         newImages.push(imagesGroup[entri]);
            //     }
            //     return true;
            // });
        });
        setDataMobile(newImages);
        setIsEnter(true);
    }

    const loadInMobile = () => {
        setIsEnter(false);
    }

    return (
        <section className="contenedor-work">
            <div className="contenedor-head">
                <div className="main-container">
                    {data&&
                        <div className="contenedor-titulos">
                            <h1>{data.data.post_title}</h1>
                            <h3>{data.contents.subtitle}</h3>
                        </div>
                    }
                    {data&&
                        <div className="contenido">
                            {isTabletOrMobile&&
                                <Link to={`/works`} className="text-loanding all">All</Link>
                            }
                            <div className="contenido-links">
                                <Link to={`/works/${data.prev_Post.post_name}`} onClick={() => {loadInMobile()}} className="text-loanding">Previous</Link>
                                <Link to={`/works/${data.next_post.post_name}`} onClick={() => {loadInMobile()}} className="text-loanding">Next</Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="contenedor-body">
                <div className="main-container">
                    {isTabletOrMobile&&
                        dataMobile&&
                            dataMobile.map((block, index) => (
                                <div key={index} className={`block one`}>
                                    <div className="item">
                                        <img src={block.url} alt={block.title}/>
                                    </div>
                                </div>
                            ))
                    }
                    {!isTabletOrMobile&&
                        <div className="contenedor-list-proyects">
                            {dataListProjects&&
                                dataListProjects.map((project, index) => (
                                    <NavLink exact activeClassName="isActive" to={`/works/${project.slug}`} data-num={index} key={index} className={`item text-proyects`}>
                                        <Markup key={index} content={project.acf.preview_content.menu_text} allowList={["strong"]}/>
                                    </NavLink>
                                ))
                            }
                        </div>
                    }
                    {!isTabletOrMobile&&
                    data&&
                        <div className="contenedor-swiper">
                            <Swiper
                                // onSwiper={setSwiper}
                                autoHeight={true}
                                slidesPerView={1}
                                direction='vertical'
                                mousewheel ={true}
                                // allowTouchMove={false}
                            >
                            {data.images.map((block, index) => {
                                switch(block.type){
                                    case '0':
                                        return (
                                        <SwiperSlide key={index}>
                                            <div className={`block final`}>
                                                <div className="item">
                                                    <img src={block.image.url} alt={block.image.title}/>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        )
                                    case '1':
                                        return(
                                        <SwiperSlide key={index}>
                                            <div className={`block one`}>
                                                <div className="item">
                                                    <img src={block.image.url} alt={block.image.title}/>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        ) 
                                    case '2':
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className={`block two`}>
                                                    <div className="item">
                                                        <img src={block.image.url} alt={block.image.title}/>
                                                    </div>
                                                    <div className="item">
                                                        <img src={block['image-2'].url} alt={block['image-2'].title}/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    case '3':
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className={`block three`}>
                                                    <div className="item">
                                                        <img src={block.image.url} alt={block.image.title}/>
                                                    </div>
                                                    <div className="item">
                                                        <img src={block['image-2'].url} alt={block['image-2'].title}/>
                                                    </div>
                                                    <div className="item">
                                                        <img src={block['image-3'].url} alt={block['image-3'].title}/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    default:
                                        return('')
                                }
                            })}
                            </Swiper>
                        </div>
                    
                    }
                </div>
            </div>
        </section>
    );
}

export default Work;
