import axios from 'axios'
import Global from '../global.js';


//constantes

const dataPage = {
    6: null,
    10: null,
    8: null,
    14: null,
    16: null
}

//types
const GET_DATA_PAGE = 'GET_DATA_PAGE';
const GET_DATA_PAGE_INSTA = 'GET_DATA_PAGE_INSTA';

//reducer

export default function pageReducer(state = dataPage, action){
    switch (action.type) {
        case GET_DATA_PAGE:
            return {...state, [action.id]: action.payload};
        case GET_DATA_PAGE_INSTA:
            return {...state, [action.id]: action.payload};
        default:
            return state
    }
}

//acciones
export const obtenerDataAccion = (id) => async (dispatch, getState) => {

    try {
        const res = await axios.get(`${Global.urlAdmin}pages/${id}`);
        dispatch({
            type: GET_DATA_PAGE,
            payload: res.data,
            id: id
        })
    } catch (error) {
        // console.log(error)
    }
}

export const obtenerDataAccionInsta = (id) => async (dispatch, getState) => {

    try {
        const arrayFields  = 'id,media_type,media_url,permalink,thumbnail_url,timestamp,caption,username,children{media_url,thumbnail_url},profile_pic';
        axios.get(`${Global.urlAdmin}options/wl_instagram`).then(({data}) => {
            axios.get(`https://graph.instagram.com/${data.id}/media`, {params: {fields: arrayFields, access_token: data.token}}).then((res) => {
                dispatch({
                    type: GET_DATA_PAGE_INSTA,
                    payload: res.data,
                    id: id
                })
            }).catch(() => false);
        }).catch(() => false);
    } catch (error) {
        // console.log(error)
    }
}

export const obtenerDataAccionHome = (id) => async (dispatch, getState) => {
    try {
        axios.get(`${Global.urlAdmin}options/wl_home`).then((res) => {
            dispatch({
                type: GET_DATA_PAGE,
                payload: res.data,
                id: id
            })
        }).catch(() => false);
    } catch (error) {
        // console.log(error)
    }
}