const Global = {
    color:{
        black: '#0D0D0D',
        white: '#ffffff',
        gray: '#3A3A3A',
        salmon: '#FF8B6C'
    },
    // urlHome: "http://atelier.canalla.agency/",
    // urlAdmin: "http://atelier.canalla.agency/admin/wp-json/wp/v2/",
    // urlEmail: "http://atelier.canalla.agency/mail/",
    urlHome: "https://ateliermadesign.com/",
    urlAdmin: "https://ateliermadesign.com/admin/wp-json/wp/v2/",
    urlEmail: "https://ateliermadesign.com/mail/",
    // urlHome: "http://192.168.0.104:8080/canalla_agency/Atelier",
    // urlAdmin: "http://192.168.0.104:8080/canalla_agency/Atelier/admin/wp-json/wp/v2/",
    // urlEmail: "http://192.168.0.104:8080/canalla_agency/Atelier/mail/",
    pages:{
        home: 6,
        studio: 10,
        contact: 16,
        work: 8,
        journal: 14
    },
    telFix: (tel) => {
        tel = tel.replace(/\(/g, '');
        tel = tel.replace(/\)/g, '');
        tel = tel.replace(/-/g, '');
        tel = tel.replace(/ /g, '');
        tel = tel.replace(/\./g, '');
        tel = tel.trim();

        return tel;
    }
}


export default Global