import React, {useRef, useEffect} from 'react';
import './Menu.scss';
import { ReactComponent as Asterisco } from '../../assets/images/asterisco.svg';


const Menu = ({color, left, animationBurgerMenu, NavLink, hideNav = false, opacity0Global, useHistory}) => {
    const contenedorBurgerMenu = useRef(null);
    const contenedorBurgerMenuChild = useRef(null);
    const contenedorTitulo = useRef(null);
    const burgerMenu = useRef(null);
    const history = useHistory();

    const toogleMenu = () => {
        animationBurgerMenu.reversed(!animationBurgerMenu.reversed());
    }

    useEffect(() => {
        animationBurgerMenu.to(contenedorBurgerMenu.current, {
            duration: 0.4,
            height: '70vh'
        }).to(contenedorBurgerMenuChild.current, {
            duration: 0.4,
            opacity: 1,
        }, '-=0.4').to('#contenedor-telefono p .show, #contenedor-correo p .show', {
            // duration: 0.4,
            x: 0,
            opacity: 1
        }, '-=0.4').to('#contenedor-telefono p .hide, #contenedor-correo p .hide', {
            // duration: 0.4,
            x: 32,
            opacity: 0
        }, '-=0.4').to(contenedorTitulo.current.children[1], {
            duration: 0.4,
            width: 'auto'
        }, '-=0.4').to('#contenedor-logo', {
            duration: 0.4,
            scale: 1.25
        }, '-=0.4').to(burgerMenu.current, {
            duration: 0.4,
            backgroundColor: color.salmon
        }, '-=0.4').to(burgerMenu.current.children[0], {
            duration: 0.4,
            y: 8,
            rotation: 45
        }, '-=0.4').to(burgerMenu.current.children[1], {
            duration: 0.4,
            opacity: 0
        }, '-=0.4').to(burgerMenu.current.children[2], {
            duration: 0.4,
            y: -7,
            rotation: -45
        }, '-=0.4').reverse();
    }, [animationBurgerMenu, color.salmon]);

    const beforeLink = (e) => {
        e.preventDefault();
        opacity0Global.restart();
        const url = e.currentTarget.getAttribute("href");
        setTimeout(() => {
            history.push(url);
        }, 200);
    }

    return (
        <section id="contenedor-menu" className="main-container contenedor-menu" ref={contenedorBurgerMenu}>
            <div id="burger-menu" className={`burger-menu ${left?'left':''}`} ref={burgerMenu} onClick={() => {toogleMenu()}}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <div className={`contenedor-titulo titulo-general ${hideNav?'hide':''}`} ref={contenedorTitulo}>
                <span>_A</span>
                <span>telier MA</span>
            </div>
            <div id="menu" className="contenedor-menu-child"  ref={contenedorBurgerMenuChild}>
                <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(e); beforeLink(e);}} to="/" className="h3Base item"><div><Asterisco /></div><span>STUDIO</span></NavLink>
                <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(e); beforeLink(e);}} to="/works" className="h3Base item"><div><Asterisco /></div><span>WORK</span></NavLink>
                <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(e); beforeLink(e);}} to="/journal" className="h3Base item"><div><Asterisco /></div><span>JOURNAL</span></NavLink>
                <NavLink exact activeClassName="isActive" onClick={(e) => {toogleMenu(e); beforeLink(e);}} to="/contact" className="h3Base item"><div><Asterisco /></div><span>SAY HELLO!</span></NavLink>
            </div>      
        </section>
    );
}

export default Menu;
