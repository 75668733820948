import React, { useEffect } from 'react';
import './Instagram.scss';
import gsap from 'gsap';

const Instagram = ({data}) => {
    useEffect(() => {
        const itemsCarrusel = document.querySelectorAll(".item-children-padre");
        for (let i = 0; i < itemsCarrusel.length; i++) {
            let item = itemsCarrusel[i];
            var carruselAnimation = gsap.timeline({repeat: -1, yoyo: true, repeatDelay: 0.5});
            for(let i = 1; i < item.children.length; i++){
                carruselAnimation.to(item, {
                    x: '-='+(item.offsetWidth / item.children.length),
                    duration: 4
                })
            }
        }
    }, []);

    return (
        <section className="contenedor-instagram-section">
            <div className="main-container">
                {data.data&&
                    data.data.map((item, index) => {
                        switch (item.media_type) {
                            case "CAROUSEL_ALBUM":
                                return(
                                    <a key={index} href={item.permalink} target="_blank"  rel="noreferrer" className="item">
                                        <div className="item-children-padre" style={{width: item.children.data.length+'00%'}}>
                                        {(item.children.data.length > 0)&&
                                            item.children.data.map((itemChildren, index2) => (
                                                (itemChildren.media_url.split(".")[4].split("?")[0] === 'mp4')?
                                                    <div key={index2} className="item-children">
                                                        <video preload="metadata" data-keepplaying autoPlay loop muted playsInline>
                                                            <source src={`${item.media_url}#t=0.01`} type="video/mp4"/>
                                                        </video>
                                                    </div>
                                                :
                                                    <div key={index2} className="item-children">
                                                        <img src={item.media_url} alt={item.id} />
                                                    </div>
                                            ))
                                        }
                                        </div>
                                    </a>
                                )
                            case "IMAGE":
                                return(
                                    <a key={index} href={item.permalink} target="_blank"  rel="noreferrer" className="item">
                                        <img src={item.media_url} alt={item.id} />
                                    </a>
                                )
                            case "VIDEO":
                                return(
                                    <a key={index} href={item.permalink} target="_blank"  rel="noreferrer" className="item">
                                        <video preload="metadata" data-keepplaying autoPlay loop muted playsInline>
                                            <source src={`${item.media_url}#t=0.01`} type="video/mp4"/>
                                        </video>
                                    </a>
                                )
                            default:
                                return('')
                        }
                    })
                }
            </div>
        </section>
    );
}

export default Instagram;
