import React, {useEffect, useRef, useState} from 'react';
import './Team.scss';

import {Markup} from 'interweave';
// import PrevStudio from '../../assets/images/prev-studio.jpg';
import gsap from 'gsap';
import videoHome from '../../assets/videos/atelier-home.mp4';
import imageHome from '../../assets/images/atelier-home.jpg';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import SwiperCore, {EffectFade, Autoplay } from 'swiper/core';

SwiperCore.use([EffectFade, Autoplay]);

const Team = ({data, dataImages, videoIsLoadOneTime, setVideoIsLoadOneTime, setIsLoading, isTabletOrMobile, timeVideo, setTimeVideo, opacityGlobal}) => {
    gsap.registerPlugin(ScrollTrigger);
    const [videoIsLoad, setVideoIsLoad] = useState(false);
    const vidRef = useRef(null);
    const vidRefContainer = useRef(null);
    useEffect(() => {
        if(videoIsLoadOneTime){
            if(isTabletOrMobile){
                // vidRef.current.currentTime = timeVideo;
                const bloqueIntroduccion = document.getElementById('contenedor-studio-introduction');
                // const contenedorMenu = document.getElementById('contenedor-menu');
                // const contenedorMenu = document.getElementById('contenedor-studio-introduction');
                let top = bloqueIntroduccion.offsetTop;
                gsap.timeline().to(vidRefContainer.current, {
                    duration: 0.001,
                    opacity: 0.2,
                    top: top,
                    left: bloqueIntroduccion.offsetLeft,
                    zIndex: -1,
                }).to('.skip', {
                    duration: 0.001,
                    opacity: 0,
                    pointerEvents: 'none'
                })
            }else{
                vidRef.current.currentTime = timeVideo;
                const bloqueIntroduccion = document.getElementById('contenedor-studio-introduction');
                const contenedorMenu = document.getElementById('contenedor-menu');
                // const contenedorMenu = document.getElementById('contenedor-studio-introduction');
                let top = contenedorMenu.offsetTop + window.innerHeight * 0.05;
                gsap.timeline().to(vidRefContainer.current, {
                    duration: 0.001,
                    opacity: 0.2,
                    top: top,
                    left: bloqueIntroduccion.offsetLeft,
                    zIndex: -1,
                }).to('.skip', {
                    duration: 0.001,
                    opacity: 0,
                    pointerEvents: 'none'
                })
            }

        }
    

        gsap.utils.toArray(".contenedor-parallax-img, .figura-parallax").forEach((item, i) => {
            gsap.to(item, {
                y: `-100%`,
                ease: "none",
                // opacity: 0,
                scrollTrigger: {
                    start: `top bottom`,
                    end: `bottom top`,
                    trigger: item,
                    scrub: true,
                //   markers: true
                }
            });
        });
        setVideoIsLoad(true);
        // gsap.utils.toArray(".figura-parallax").forEach((item, i) => {
        //     gsap.to(item, {
        //         y: `-100%`,
        //         ease: "none",
        //         // opacity: 0,
        //         scrollTrigger: {
        //             start: `top bottom`,
        //             end: `bottom top`,
        //             trigger: item,
        //             scrub: true,
        //             // markers: true
        //         }
        //     });
        // });
        opacityGlobal.restart();
    }, [setVideoIsLoad]);

    
    // if(data && videoIsLoad && !videoIsLoadOneTime){
    //     if(isTabletOrMobile){

    //     }else{
    //         vidRef.current.play();
    //     }
    // }

    const videoEnd = (e) => {
        const video = e;
        if(!timeVideo){
            // bloqueIntroduccion.offsetTop + bloqueIntroduccion.clientHeight
            const bloqueIntroduccion = document.getElementById('contenedor-studio-introduction');
            gsap.timeline().to('.skip', {
                duration: 0.001,
                opacity: 0,
                pointerEvents: 'none'
            }).to(vidRefContainer.current, {
                duration: 0.6,
                opacity: 0.2
                // filter: 'grayscale(100%) opacity(0.2)'
            }).to(vidRefContainer.current, {
                duration: 0.4,
                top: bloqueIntroduccion.offsetTop,
                left: bloqueIntroduccion.offsetLeft
            }).to(vidRefContainer.current, {
                duration: 0.001,
                zIndex: -1,
                // onComplete: () => {
                //     setVideoIsLoadOneTime(true);
                //     if(!isTabletOrMobile)
                //         setTimeVideo(video.target.currentTime);
                // } 
            })
        }
    }

    // if(videoIsLoad && !videoIsLoadOneTime){
    //     if(!isTabletOrMobile){
    //         vidRef.current.play();
    //     }else{
    //         videoEnd()
    //     }
    // }

    const setEndVideo = () => {
        vidRef.current.currentTime = 12;
        // vidRef.current.fastSeek(vidRef.current.seekable.end(0));
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         videoEnd()
    //     }, 600);
    // }, [])
    

    return (
        <section className="contenedor-team">
            {/* <div ref={vidRefContainer} className="video"> */}
                {/* <div className='image-b'>
                    <img src={imageHome} alt={"Background"} />
                </div> */}
                {/* {!isTabletOrMobile&&<>
                    <video ref={vidRef} onEnded={(e) => {videoEnd(e)}} preload="metadata" muted="muted" data-keepplaying playsInline>
                        <source src={`${videoHome}#t=0.01`} type="video/mp4" />
                    </video>
                    <button className="skip text-input" onClick={() => {setEndVideo()}}>
                        Skip animation
                    </button>
                </>} */}
            {/* </div> */}
            {/* <div className="contenedor-parallax-img one">
                {dataImages&&
                    <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            effect="fade"
                            allowTouchMove={false}
                            loop={true}
                            autoplay={true}
                        >
                        {dataImages.images_1.map((slide, index) => (
                            <SwiperSlide key={index}>
                                 <img src={slide.image.url} alt={slide.image.title}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }
            </div> */}
            {/* <div className="contenedor-parallax-img two">
                {dataImages&&
                    <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            effect="fade"
                            allowTouchMove={false}
                            loop={true}
                            autoplay={true}
                        >
                        {dataImages.images_2.map((slide, index) => (
                            <SwiperSlide key={index}>
                                 <img src={slide.image.url} alt={slide.image.title}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }
            </div> */}
            {/* <div className="contenedor-parallax-img three">
                {dataImages&&
                    <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            effect="fade"
                            allowTouchMove={false}
                            loop={true}
                            autoplay={true}
                        >
                        {dataImages.images_3.map((slide, index) => (
                            <SwiperSlide key={index}>
                                 <img src={slide.image.url} alt={slide.image.title}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                }
            </div> */}
            <div className="figura-parallax rectangulo"></div>
            <div className="figura-parallax circle"></div>
            <div className="main-container">
                {data&&
                    data.map(({member}, index) => (
                        <div key={index} className="member">
                            <div className="contenedor-img">
                                {(member.image)&&
                                <img 
                                    src={member.image.sizes.large} 
                                    srcSet={`${member.image.sizes.medium} 300w, ${member.image.sizes['medium_large']} 1000w, ${member.image.sizes.large} 2000w`} 
                                    sizes="(max-width: 400px) 300px, (max-width: 1200px) 1000px, 2000px"
                                    alt={(member.image.description)?member.image.description:member.image.title}
                                />
                                }
                            </div>
                            <h1>{member.name}</h1>
                            <Markup content={member.description} tagName="p"/>
                        </div>
                    ))
                }
            </div>
        </section>
    );
}

export default Team;
