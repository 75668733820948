import React, {useEffect} from 'react';
import './Journal.scss';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataAccionInsta} from '../../redux/pageDucks.js';
import Instagram from '../../components/Instagram/Instagram';

const Journal = ({idPage, setIsLoading, opacityGlobal, opacity0Global}) => {
    const dispatch = useDispatch()
    const data = useSelector(store => store.page[idPage])

    useEffect(() => {
        if(!data){
            // setIsLoading(true);
            dispatch(obtenerDataAccionInsta(idPage));
        }
        window.scrollTo(0, 0);
    }, [dispatch, idPage, data]);

    
    // if(data){
    //     setIsLoading(false);
    // }

    if(data &&  opacityGlobal.paused()){
        opacityGlobal.restart();
    }
   

    return (
        <>
        {data&&
            <>
                <Instagram data={data}/>
            </>
        }
        </>
    );
}

export default Journal;
