import React, {useEffect, useRef, useState} from 'react';
import './Navbar.scss';
// import { ReactComponent as Logo } from '../../assets/images/logo.png';
import Logo from '../../assets/images/logo.svg';
// import { ReactComponent as LineaRoja } from '../../assets/images/linea_roja.svg';
import {useDispatch, useSelector} from 'react-redux';
import {obtenerDataAccion} from '../../redux/pageDucks.js';
import {Markup} from 'interweave';

const Navbar = ({idPage, telFix, NavLink, animationBurgerMenu, hideNav, opacity0Global, useHistory, paddingLeft, setPaddingLeft, paddingRight, setPaddingRight, interna = false}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const data = useSelector(store => store.page[idPage]);
    const imageLogo = useRef(null);
    const mail = useRef(null);
    useEffect(() => {
        if(!data){
            dispatch(obtenerDataAccion(idPage));
        }
        // console.log(mail);
        if(!interna){
            setPaddingRight((window.innerWidth - (mail.current.offsetLeft + mail.current.clientWidth)) - window.innerWidth * 0.075);
            setPaddingLeft(imageLogo.current.offsetWidth * 0.62 - (window.innerWidth * 0.075 - window.innerWidth * 0.04));
            window.addEventListener("resize", () => {
                setPaddingLeft(imageLogo.current.offsetWidth * 0.62 - (window.innerWidth * 0.075 - window.innerWidth * 0.04));
                setPaddingRight((window.innerWidth - (mail.current.offsetLeft + mail.current.clientWidth)) - (window.innerWidth * 0.075));
            });
        }
    }, [dispatch, idPage, data]);

    const toogleMenu = () => {
        if(!animationBurgerMenu.reversed()){
            animationBurgerMenu.reversed(!animationBurgerMenu.reversed());
        }
    }

    const beforeLink = (e) => {
        e.preventDefault();
        opacity0Global.restart();
        const url = e.currentTarget.getAttribute("href");
        setTimeout(() => {
            history.push(url);
        }, 200);
    }

    return (
        <header>
            {!hideNav&&
                <nav>
                    <NavLink to="/" onClick={(e) => {toogleMenu(); beforeLink(e);}} id="contenedor-logo" className={`contenedor-logo`}>
                        {/* <Logo /> */}
                        <img ref={imageLogo} src={Logo} alt="atelier"/>
                        {/* <div className="contenedor-linea">
                            <LineaRoja />
                        </div> */}
                    </NavLink>
                    <div className="contenedor-right">
                        <a href={data&&'tel:' + telFix(data.acf.navbar.phone)} id="contenedor-telefono" className="contenedor-telefono">
                            <p>
                                <span className="hide">C:</span>
                                <span className="show">{data&&data.acf.navbar.phone}</span>
                            </p>
                        </a>
                        <a ref={mail} href={data&&'mailto:' + data.acf.navbar.email} id="contenedor-correo" className="contenedor-correo">
                            <p>
                                <span className="hide">M:</span>
                                <span className="show">{data&&data.acf.navbar.email}</span>
                            </p>
                        </a>
                        <div className="contenedor-text-right">
                            {data&&
                                <Markup content={data.acf.navbar.right_corner_text} tagName="p"/>
                            }
                        </div>
                    </div>
                </nav>
            }
            {data&&
                <a href={data.acf.navbar.instagram_button.instagram_url} target="_blank" rel="noreferrer" className="contenedor-instagram">
                    <div className="text-fixed-buttons">{data.acf.navbar.instagram_button.text}</div>
                </a>
            }
            {/* <div className="contenedor-since">
                <div className="text-fixed-buttons">{data&&data.acf.navbar.right_text}</div>
            </div> */}
        </header>
    );
}

export default Navbar;
