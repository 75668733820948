import React from 'react';
import './Loading.scss';

const Loading = ({isLoading}) => {
    return (
        <div className={`loading ${(isLoading)?'isLoading':''}`}>
            <div className="spinner">
                <div className="forma circle"></div>
                {/* <div className="forma square"></div>
                <div className="forma triangle"></div>
                <div className="forma diamond"></div> */}
            </div>
            <div className="text-loanding">Loading...</div>
        </div>
    );
}

export default Loading;
