import React from 'react';
import './StudioIntroduction.scss';

import {Markup} from 'interweave';

const StudioIntroduction = ({data}) => {
    return (
        <section id="contenedor-studio-introduction" className="contenedor-studio-introduction">
            <div className="main-container">
                {data.image&&
                    <div className='contenedor-img'>
                        <img 
                            src={data.image.sizes.large} 
                            srcSet={`${data.image.sizes.medium} 300w, ${data.image.sizes['medium_large']} 1000w, ${data.image.sizes.large} 2000w`} 
                            sizes="(max-width: 400px) 300px, (max-width: 1200px) 1000px, 2000px"
                            alt={(data.image.description)?data.image.description:data.image.title}
                        />
                    </div>
                }
                <h2 className={`${!data.image?'padding-top':''}`}>{data.title}</h2>
                <Markup content={data.text} tagName="p"/>
            </div>
        </section>
    );
}

export default StudioIntroduction;
